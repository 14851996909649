var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { fontSize, height, space } from 'styled-system';
import { linkStyles } from '../../common/components/Link';
import RichTextBlock from '../../common/components/RichText';
import { boxSizing, overflowX, overflowY, textDecoration, } from '../../common/theme/system-utilities';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), space, height, boxSizing, overflowX, overflowY);
var containerStyles = {
    height: '100%',
    boxSizing: 'border-box',
    overflowY: 'auto',
    overflowX: 'hidden',
};
var StyledRichTextBlock = styled(RichTextBlock)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  p,\n  li {\n    ", ";\n  }\n  a {\n    ", ";\n    ", ";\n    color: inherit;\n  }\n"], ["\n  ", ";\n  p,\n  li {\n    ", ";\n  }\n  a {\n    ", ";\n    ", ";\n    color: inherit;\n  }\n"])), space, fontSize, textDecoration, linkStyles);
var styledRichTextBlockStyles = {
    p: ['sp_16', 'sp_24', null, 'sp_32'],
    fontSize: ['fs_14', null, 'fs_16', 'fs_18'],
    textDecoration: 'underline',
    styled: true,
};
var Content = function (_a) {
    var html = _a.html, content = _a.content, p = _a.p;
    return (React.createElement(Container, __assign({}, containerStyles), content || (React.createElement(StyledRichTextBlock, __assign({ p: p, dangerouslySetInnerHTML: { __html: html }, "data-testid": "html" }, styledRichTextBlockStyles)))));
};
export default Content;
var templateObject_1, templateObject_2;
