var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { alignItems, backgroundColor, borderRadius, boxShadow, color, display, flex, maxHeight, position, size, space, width, zIndex, } from 'styled-system';
import { Button } from '../../common/components/Button';
import Icon from '../../common/components/Icon';
import { HeadlineH7 } from '../../common/components/Typography';
import { DRAWER_CLOSE_BUTTON_Z_INDEX } from '../../common/constants/z-index';
import { useLocalization } from '../../common/providers/LocaleProvider';
import { textWithEllipsis } from '../../common/theme/helper';
import { boxSizing, cursor, themeHeight, themeLeft, themeRight, themeWidth, } from '../../common/theme/system-utilities';
var ICON_SIZE = 32;
var HeaderSection = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), alignItems, backgroundColor, boxShadow, boxSizing, color, display, space, themeHeight, themeWidth, width, zIndex);
var headerSectionStyles = {
    alignItems: 'center',
    backgroundColor: 'sys.neutral.background.default',
    boxShadow: 'bottom',
    boxSizing: 'border-box',
    color: 'sys.neutral.text.strong',
    display: 'flex',
    px: 'sp_20',
    py: 'sp_0',
    themeHeight: ['54px', 'sp_64'],
    themeWidth: '100%',
    width: 1,
    zIndex: DRAWER_CLOSE_BUTTON_Z_INDEX,
};
var CloseIcon = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), size, space, cursor, color);
var closeIconStyle = {
    size: ICON_SIZE,
    color: 'sys.neutral.icon.default',
};
var CloseButton = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), cursor, position, themeRight, themeLeft, borderRadius);
var buttonStyles = function (themeSide) {
    var _a;
    return (_a = {
            cursor: 'pointer',
            kind: 'bare',
            position: 'absolute'
        },
        _a[themeSide] = 'sp_8',
        _a.p = 'sp_8',
        _a.borderRadius = 'circle',
        _a);
};
var Title = styled(HeadlineH7)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), maxHeight, flex, textWithEllipsis());
var titleStyle = {
    textAlign: 'center',
    mr: 'sp_32',
    as: 'h1',
    flex: 1,
};
var Header = function (_a) {
    var onClick = _a.onClick, title = _a.title, id = _a.id, withBackButton = _a.withBackButton;
    var t = useLocalization().t;
    var text = title !== null && title !== void 0 ? title : t('h24_important_info');
    return (React.createElement(HeaderSection, __assign({}, headerSectionStyles),
        React.createElement(CloseButton, __assign({}, buttonStyles(withBackButton ? 'themeLeft' : 'themeRight'), { "data-testid": withBackButton ? 'back-button' : 'close-button', onClick: onClick, "aria-label": t(withBackButton ? 'h24_back' : 'h24_close'), autoFocus: false }),
            React.createElement(CloseIcon, __assign({}, closeIconStyle, { name: withBackButton ? 'arrowLeft' : 'close' }))),
        React.createElement(Title, __assign({ "data-testid": "drawer-header-title" }, (id && { id: id }), titleStyle), text)));
};
export default Header;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
